@import "https://fonts.googleapis.com/css?family=Montserrat:300, 400, 500, 700&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-family: Montserrat, sans-serif;
  font-size: 10px;
}

a {
  text-decoration: none;
}

.container {
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

p, li {
  color: #000;
  letter-spacing: .05rem;
  margin-top: 5px;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.5rem;
}

.section-title {
  color: #000;
  text-transform: uppercase;
  letter-spacing: .2rem;
  text-align: center;
  margin-bottom: 10px;
  font-size: 4rem;
  font-weight: 300;
}

.section-title span {
  color: #dc143c;
}

.cta {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .1rem;
  background-color: #0000;
  border: 2px solid #dc143c;
  margin-top: 30px;
  padding: 10px 30px;
  font-size: 2rem;
  transition: background-color .3s, color .3s;
  display: inline-block;
}

.cta:hover {
  color: #fff;
  background-color: #dc143c;
}

.brand h1 {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
  font-size: 3rem;
}

.brand h1 span {
  color: #dc143c;
}

#header {
  z-index: 1000;
  width: 100vw;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}

#header .header {
  min-height: 8vh;
  background-color: #1f1e1e3d;
  transition: background-color .3s;
}

#header .nav-bar {
  width: 100%;
  height: 100%;
  max-width: 1300px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  display: flex;
}

#header .nav-list ul {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: #1f1e1e;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  transition: left .5s;
  display: flex;
  position: absolute;
  top: 0;
  left: 100%;
  overflow-x: hidden;
}

#header .nav-list ul.active {
  left: 0%;
}

#header .nav-list ul a {
  letter-spacing: .2rem;
  color: #fff;
  text-transform: uppercase;
  padding: 20px;
  font-size: 2.5rem;
  font-weight: 500;
  text-decoration: none;
  display: block;
}

#header .nav-list ul a:after {
  content: attr(data-after);
  color: #f0f8ff05;
  letter-spacing: 50px;
  z-index: -1;
  font-size: 13rem;
  transition: letter-spacing .3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(0);
}

#header .nav-list ul li:hover a:after {
  letter-spacing: initial;
  transform: translate(-50%, -50%)scale(1);
}

#header .nav-list ul li:hover a {
  color: #dc143c;
}

#header .hamburger {
  height: 60px;
  width: 60px;
  z-index: 100;
  cursor: pointer;
  border: 3px solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  display: flex;
  position: relative;
  transform: scale(.8);
}

#header .hamburger:after {
  content: "";
  height: 100%;
  width: 100%;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: hamburger_puls 1s infinite;
  position: absolute;
}

#header .hamburger .bar {
  height: 2px;
  width: 30px;
  z-index: -1;
  background-color: #fff;
  position: relative;
}

#header .hamburger .bar:after, #header .hamburger .bar:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #fff;
  transition: top .3s, bottom .3s;
  position: absolute;
  left: 0;
}

#header .hamburger .bar:after {
  top: 8px;
}

#header .hamburger .bar:before {
  bottom: 8px;
}

#header .hamburger.active .bar:before {
  bottom: 0;
}

#header .hamburger.active .bar:after {
  top: 0;
}

#hero {
  z-index: 1;
  background-image: url("david.8f656aa9.png");
  background-position: top;
  background-size: cover;
  position: relative;
}

#hero:after {
  content: "";
  height: 100%;
  width: 100%;
  opacity: .7;
  z-index: -1;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
}

#hero .hero {
  max-width: 1200px;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 0 50px;
}

#hero h1 {
  width: fit-content;
  color: #0000;
  font-size: 4rem;
  animation: text_reveal .5s 1s forwards;
  display: block;
  position: relative;
}

#hero h1:nth-child(1) {
  animation-delay: 1s;
}

#hero h1:nth-child(2) {
  animation-delay: 2s;
}

#hero h1:nth-child(3) {
  animation: text_reveal_name .5s 3s forwards;
}

#hero h1 span {
  height: 100%;
  width: 0;
  background-color: #dc143c;
  animation: text_reveal_box 1s .5s;
  position: absolute;
  top: 0;
  left: 0;
}

#hero h1:nth-child(1) span {
  animation-delay: .5s;
}

#hero h1:nth-child(2) span {
  animation-delay: 1.5s;
}

#hero h1:nth-child(3) span {
  animation-delay: 2.5s;
}

#services .services {
  text-align: center;
  max-width: 1500px;
  flex-direction: column;
  margin: 0 auto;
  padding: 100px 0;
}

#services .service-top {
  max-width: 500px;
  margin: 0 auto;
}

#services .service-bottom {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  display: flex;
}

#services .service-item {
  z-index: 1;
  background-image: url("img-1.83ea9e05.png");
  background-size: cover;
  border-radius: 10px;
  flex-direction: column;
  flex-basis: 80%;
  justify-content: center;
  align-items: flex-start;
  margin: 10px 5%;
  padding: 30px;
  display: flex;
  position: relative;
  overflow: hidden;
}

#services .service-item:after {
  content: "";
  height: 100%;
  width: 100%;
  opacity: .9;
  z-index: -1;
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  position: absolute;
  top: 0;
  left: 0;
}

#services .service-bottom .icon {
  height: 80px;
  width: 80px;
  margin-bottom: 20px;
}

#services .service-item h2 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 2rem;
}

#services .service-item p {
  color: #fff;
  text-align: left;
}

#projects .projects {
  max-width: 1200px;
  flex-direction: column;
  margin: 0 auto;
  padding: 100px 0;
}

#projects .projects-header h1 {
  margin-bottom: 50px;
}

#projects .all-projects {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#projects .project-item {
  width: 80%;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  display: flex;
  overflow: hidden;
}

#projects .project-info {
  height: 100%;
  color: #fff;
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  flex-direction: column;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  padding: 30px;
  display: flex;
}

#projects .project-info h1 {
  font-size: 4rem;
  font-weight: 500;
}

#projects .project-info h2 {
  margin-top: 10px;
  font-size: 1.8rem;
  font-weight: 500;
}

#projects .project-info p {
  color: #fff;
}

#projects .project-img {
  height: 300px;
  flex-basis: 50%;
  position: relative;
  overflow: hidden;
}

#projects .project-img:after {
  content: "";
  height: 100%;
  width: 100%;
  opacity: .7;
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  position: absolute;
  top: 0;
  left: 0;
}

#projects .project-img img {
  transition: transform .3s;
}

#projects .project-item:hover .project-img img {
  transform: scale(1.1);
}

#about .about {
  text-align: center;
  max-width: 1200px;
  flex-direction: column-reverse;
  margin: 0 auto;
  padding: 100px 20px;
}

#about .col-left {
  width: 250px;
  height: 360px;
}

#about .col-right {
  width: 100%;
}

#about .col-right h2 {
  letter-spacing: .2rem;
  margin-bottom: 10px;
  font-size: 1.8rem;
  font-weight: 500;
}

#about .col-right p {
  margin-bottom: 20px;
}

#about .col-right .cta {
  color: #000;
  margin-bottom: 50px;
  padding: 10px 20px;
  font-size: 2rem;
}

#about .col-left .about-img {
  height: 100%;
  width: 100%;
  border: 10px solid #fff;
  position: relative;
}

#about .col-left .about-img:after {
  content: "";
  height: 98%;
  width: 98%;
  z-index: -1;
  border: 7px solid #dc143c;
  position: absolute;
  top: 19px;
  left: -33px;
}

#contact .contact {
  max-width: 1200px;
  width: 90%;
  flex-direction: column;
  margin: 0 auto;
}

#contact h1 {
  padding: 50px;
}

#contact .contact-items {
  width: 100%;
}

#contact .contact-item {
  width: 80%;
  text-align: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;
  padding: 30px;
  transition: box-shadow .3s;
  display: flex;
  box-shadow: 0 0 18px #0000002c;
}

#contact .contact-item:hover {
  box-shadow: 0 0 5px #0000002c;
}

#contact .icon {
  width: 70px;
  margin: 0 auto 10px;
}

#contact .contact-info h1 {
  margin-bottom: 5px;
  font-size: 2.5rem;
  font-weight: 500;
}

#contact .contact-info h2 {
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
}

#footer {
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
}

#footer .footer {
  min-height: 200px;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 10px;
}

#footer h2 {
  color: #fff;
  letter-spacing: .1rem;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.8rem;
  font-weight: 500;
}

#footer .social-icon {
  margin-bottom: 30px;
  display: flex;
}

#footer .social-item {
  height: 50px;
  width: 50px;
  margin: 0 5px;
}

#footer .social-item img {
  filter: grayscale();
  transition: filter .3s;
}

#footer .social-item:hover img {
  filter: grayscale(0);
}

#footer p {
  color: #fff;
  font-size: 1.3rem;
}

@keyframes hamburger_puls {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1.4);
  }
}

@keyframes text_reveal_box {
  50% {
    width: 100%;
    left: 0;
  }

  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes text_reveal {
  100% {
    color: #fff;
  }
}

@keyframes text_reveal_name {
  100% {
    color: #dc143c;
    font-weight: 500;
  }
}

@media only screen and (max-width: 767px) {
  #projects .projects-header p {
    text-align: center;
    padding: 0 10px 50px;
  }
}

@media only screen and (min-width: 768px) {
  .cta {
    padding: 20px 60px;
    font-size: 2.5rem;
  }

  h1.section-title {
    font-size: 6rem;
  }

  #hero h1 {
    font-size: 7rem;
  }

  #services .service-bottom .service-item {
    flex-basis: 45%;
    margin: 2.5%;
  }

  #projects .project-item {
    flex-direction: row;
  }

  #projects .project-item:nth-child(2n) {
    flex-direction: row-reverse;
  }

  #projects .project-item {
    height: 400px;
    width: 100%;
    border-radius: 0;
    margin: 0;
  }

  #projects .all-projects .project-info, #projects .all-projects .project-img {
    height: 100%;
  }

  #projects .projects-header p {
    text-align: center;
    padding: 0 150px 50px;
  }

  #about .about {
    flex-direction: row;
  }

  #about .col-left {
    width: 600px;
    height: 400px;
    padding-left: 60px;
  }

  #about .about .col-left .about-img:after {
    height: 98%;
    width: 98%;
    border: 10px solid #dc143c;
    top: 34px;
    left: -45px;
  }

  #about .col-right {
    text-align: left;
    padding: 30px;
  }

  #about .col-right h1 {
    text-align: left;
  }

  #contact .contact {
    min-width: 20vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  #contact .contact-items {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0;
    display: flex;
  }

  #contact .contact-item {
    width: 30%;
    flex-direction: row;
    margin: 0;
  }

  #contact .contact-item .icon {
    height: 100px;
    width: 100px;
  }

  #contact .contact-item .icon img {
    object-fit: contain;
  }

  #contact .contact-item .contact-info {
    width: 100%;
    text-align: left;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  #header .hamburger {
    display: none;
  }

  #header .nav-list ul {
    position: initial;
    height: auto;
    width: fit-content;
    background-color: #0000;
    display: block;
  }

  #header .nav-list ul li {
    display: inline-block;
  }

  #header .nav-list ul li a {
    font-size: 1.8rem;
  }

  #header .nav-list ul a:after {
    display: none;
  }

  #services .service-bottom .service-item {
    flex-basis: 22%;
    margin: 1.5%;
  }

  #projects .projects-header p {
    text-align: center;
    padding: 0 150px 50px;
  }
}

/*# sourceMappingURL=index.597634cd.css.map */
